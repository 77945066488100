<template>
  <div class="ServiceMall">
      <router-view></router-view>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
.ServiceMall{
  height: 100%;
}
</style>